@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

:root {
  --primary: #3c81e9;
  --secondary: #f9f9f9;
  --main_text: #222222;
  --sec_text: #a4a4a4;
  --family-Inter: "Inter", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: var(--family-Inter) !important;
  color: var(--main_text);
}

body {
  font-family: var(--family-Inter);
  background: #ffffff;
  overflow-x: hidden;
}

a,
a:hover {
  text-decoration: none;
  color: initial;
}

textarea,
button,
input {
  outline: none;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  display: none;
}
